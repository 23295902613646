import { render, staticRenderFns } from "./incoming-email.html?vue&type=template&id=7a2cd566&scoped=true&external"
import script from "./incoming-email.ts?vue&type=script&lang=ts&external"
export * from "./incoming-email.ts?vue&type=script&lang=ts&external"
import style0 from "./incoming-email.scss?vue&type=style&index=0&id=7a2cd566&prod&scoped=true&lang=scss&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a2cd566",
  null
  
)

/* custom blocks */
import block0 from "./incoming-email.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fusr%2Fsrc%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fcompany%2Fincoming-email%2Fincoming-email.vue&external"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VIcon})
