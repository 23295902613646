import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';

const name = 'incoming-email-settings-view';
const logger = new Logger(name);
const authModule = namespace('auth');
const emailDomain = process.env.VUE_APP_EMAIL_DOMAIN;

@Component({ name: name })
export default class IncomingEmailSettingsView extends Vue {
  @authModule.Getter('getAccount')
  private account!: any;
  get getMailToSuppliers(): string {
    return this.getMailTo('suppliers');
  }
  get getMailToAddresses(): string {
    return this.getMailTo('addresses');
  }
  private getMailTo(to: string): string {
    const mailto = `${to}-${this.account.profile.main_organization_id}@${emailDomain}`;
    return mailto;
  }
}
